import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import { espirituosas } from "../../data/espirituosa";
import SEO from "../../components/SEO/Seo";

const Espirituosas = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Spirit. Licores"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  -Blue Sky SA - Botellas de vidrio . Spirit. Licores, bebidas alcohólicas, espirituosas. Botellas para vermouth, cognac, licor, pisco, ron, cacao, cherry. Alta, baja, con picada, sin picada, 330, 375, 645, 665, 750, 900 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético, screw caps y gotero. Mendoza - Argentina."
        description=" Encuentra botellas Spirit. Licores  en Blue Sky SA.  Blue Sky SA - Botellas de vidrio . Spirit. Licores, bebidas alcohólicas, espirituosas. Botellas para vermouth, cognac, licor, pisco, ron, cacao, cherry. Alta, baja, con picada, sin picada, 330, 375, 645, 665, 750, 900 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético, screw caps y gotero. Mendoza - Argentina."
      />
      <Products subtitle="Espirituosas">
        <ProductCard data={espirituosas} />
      </Products>
    </>
  );
};

export default Espirituosas;
